import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    isLoading: false
  },

  mutations: {
    token(state, val) {
      state.token = val;
    }
  },

  actions: {
    async initApp() {
      console.log('\n--- initApp');
    },

    async validateToken({ commit }) {
      try {
        const token = localStorage.getItem('token') || null;
        // console.log('validateToken', token);
        if (!token) return Promise.reject('token not available');
        await axios.post(
          `${process.env.VUE_APP_API_ENDPOINT}/rest/v1/validate-token`,
          {
            token
          }
        );
        localStorage.setItem('token', token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        commit('token', token);
        Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    logout({ commit }) {
      localStorage.removeItem('token');
      commit('token', null);
    }
  },

  getters: {}
});
