<template>
  <div class="quotes">
    <h1>Quotes ({{ quotes.length }})</h1>
    <p class="quote mb-3" v-for="q in quotes" :key="q._id">
      {{ q.quote }} <span class="author">{{ q.author }}</span
      ><span class="tags">{{ q.tags ? q.tags.toString() : '' }}</span>
    </p>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  async created() {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_ENDPOINT}/rest/v1/quotes`
      );
      this.quotes = data;
    } catch (error) {
      console.error(error);
    }
  },
  data() {
    return {
      quotes: []
    };
  }
};
</script>

<style lang="scss" scoped></style>
