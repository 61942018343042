<template>
  <div class="pages">
    <h1>Pages ({{ pages.length }})</h1>
    <button
      class="button is-primary"
      @click="crawl"
      :disabled="!selected.length"
    >
      Crawl ({{ selected.length }})
    </button>
    <button class="button ml-2" @click="queue" :disabled="!selected.length">
      Queue Crawl ({{ selected.length }})
    </button>
    <table class="table is-striped is-narrow is-fullwidth">
      <thead>
        <tr>
          <td>Select</td>
          <td>URL</td>
          <td>Last Updated</td>
          <td>Last Crawled</td>
          <td>Status</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="p in pages" :key="p._id">
          <td>
            <b-checkbox v-model="selected" :native-value="p.url"></b-checkbox>
          </td>
          <td>{{ p.url }}</td>
          <td>{{ lastUpdated(p.updatedAt) }}</td>
          <td>{{ lastUpdated(p.lastCrawled) }}</td>
          <td>{{ p.status }}</td>
        </tr>
        <tr></tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  async created() {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_ENDPOINT}/rest/v1/pages`
      );
      this.pages = data;
    } catch (error) {
      console.error(error);
    }
  },
  data() {
    return {
      pages: [],
      selected: []
    };
  },
  methods: {
    lastUpdated(str) {
      if (!str) return '- -';
      const d = new Date(str);
      return `${d.toLocaleDateString()} - ${d.toLocaleTimeString()}`;
    },
    async crawl() {
      const urls = this.selected;
      await Promise.all(
        urls.map(
          async url =>
            await axios
              .get(
                `${process.env.VUE_APP_API_ENDPOINT}/rest/v1/crawl?url=${url}`
              )
              .catch(err => console.error('crawl error', err))
        )
      );
      location.reload();
    },
    async queue() {
      const pages = this.selected;
      await axios
        .post(`${process.env.VUE_APP_API_ENDPOINT}/rest/bull/queue`, { pages })
        .catch(err => console.error('crawl error', err));
      this.$buefy.toast.open({
        message: `${pages.length} pages added to queue`,
        type: 'is-success'
      });
      this.selected = [];
    }
  }
};
</script>

<style lang="scss" scoped></style>
