<template>
  <section class="not-found">
    <h1>404 - page not found</h1>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
