<template>
  <div class="home">
    <section>
      <h3 class="mt-3 mb-2">Select website to crawl</h3>
      <div class="field">
        <b-radio v-model="radio" native-value="brainyQuote">
          BrainyQuote
        </b-radio>
      </div>

      <!-- <b-field label="Quotes to crawl">
        <b-autocomplete
          placeholder="Person to crawl"
          :data="options"
          field="author"
          @select="option => (selected = option)"
          clearable
        >
        </b-autocomplete>
      </b-field> -->

      <b-field label="Quotes to crawl">
        <b-select
          placeholder="Select a Person"
          icon="account"
          v-model="selected"
        >
          <option v-for="option in options" :value="option" :key="option.url">
            {{ option.author }}
          </option>
        </b-select>
      </b-field>

      <b-button
        class="is-primary"
        :disabled="!selected || !selected.url"
        :loading="isCrawling"
        @click="crawl"
        >Crawl</b-button
      >
    </section>
    <section v-if="quotes.length" class="mt-3">
      <div class="columns">
        <div class="column">
          <h3 class="mb-2">{{ selected.author }} ({{ quotes.length }})</h3>
          <p class="quote mb-3" v-for="(q, index) in quotes" :key="index">
            {{ q.quote }} <span class="author">{{ q.author }}</span>
          </p>
        </div>

        <div class="column">
          <a
            v-for="link in pages"
            class="related-link"
            :key="link"
            :href="link"
            target="_blank"
          >
            {{ link }}
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      radio: 'brainyQuote',
      isCrawling: false,
      options: [
        {
          author: 'Bruce Lee',
          url: 'https://www.brainyquote.com/authors/bruce-lee-quotes'
        },
        {
          author: 'Ruth Bader Ginsburg',
          url: 'https://www.brainyquote.com/authors/ruth-bader-ginsburg-quotes'
        },
        {
          author: 'Will Rodgers',
          url: 'https://www.brainyquote.com/authors/will-rogers-quotes'
        },
        {
          author: 'Dalai Lama',
          url: 'https://www.brainyquote.com/authors/dalai-lama-quotes'
        },
        {
          author: 'Martin Luther King Jr.',
          url:
            'https://www.brainyquote.com/authors/martin-luther-king-jr-quotes'
        },
        {
          author: 'Henry David Thoreau',
          url: 'https://www.brainyquote.com/authors/henry-david-thoreau-quotes'
        },
        {
          author: 'Ronald Reagan',
          url: 'https://www.brainyquote.com/authors/ronald-reagan-quotes'
        },
        {
          author: 'Inspirational Quotes',
          url: 'https://www.brainyquote.com/topics/inspirational-quotes'
        },
        {
          author: 'Funny Quotes',
          url: 'https://www.brainyquote.com/topics/funny-quotes'
        }
      ],
      selected: null,
      quotes: [],
      pages: []
    };
  },
  methods: {
    async crawl() {
      try {
        this.isCrawling = true;
        const { data } = await axios.get(
          `${
            process.env.VUE_APP_API_ENDPOINT
          }/rest/v1/crawl?url=${encodeURIComponent(this.selected.url)}`
        );
        this.isCrawling = false;
        const { quotes, pages } = data;
        this.quotes = quotes;
        this.pages = pages;
      } catch (error) {
        console.error(error);
      }
    }
  },

  watch: {
    selected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.quotes = [];
        this.pages = [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
a.related-link {
  display: block;
}
</style>
