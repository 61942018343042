<template>
  <div class="login">
    <h1>Login</h1>
    <section class="mb-4">
      <button @click="login" class="button is-primary">
        Login via LinkedIn
      </button>
      <button @click="getMe" class="button is-info">/me</button>
      <p class="mt-3">AUTH: {{ auth }}</p>
      <p>ME: {{ me }}</p>
    </section>
    <section>
      <b-field label="Password">
        <b-input v-model="pw" type="password"></b-input>
      </b-field>
      <button class="button" @click="loginServer">
        Login
      </button>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import Pizzly from 'pizzly-js';
const pizzly = new Pizzly({ host: 'pizzy-test.herokuapp.com' }); // Initialize Pizzly
const myAPI = pizzly.integration('linkedin'); // Replace with the API slugname

export default {
  data() {
    return {
      auth: null,
      me: null,
      pw: null
    };
  },
  methods: {
    async loginServer() {
      try {
        const { data } = await axios.post(
          `${process.env.VUE_APP_API_ENDPOINT}/rest/v1/login`,
          {
            pw: this.pw
          }
        );
        localStorage.setItem('token', data.token);
        this.$router.push({ name: 'Home' });
      } catch (error) {
        this.$buefy.toast.open({
          message: `invalid password`,
          type: 'is-danger'
        });
        this.pw = null;
      }
    },

    login() {
      myAPI
        .connect()
        .then(({ authId }) => {
          console.log('Sucessfully connected!', authId);
          this.auth = authId;
        })
        .catch(err => {
          console.error(err);
        });
    },
    getMe() {
      myAPI
        .auth(this.auth) // Replace with the authId previously obtained
        .get('/me') // Replace with a valid endpoint of the API
        .then(response => response.json())
        .then(json => {
          this.me = json;
          console.log(json);
        })
        .catch(console.error);
    }
  }
};
</script>

<style lang="scss" scoped></style>
