<template>
  <div :class="'app-layout px-4 py-4 route-' + pageName">
    <b-loading is-full-page :active.sync="isLoading" can-cancel></b-loading>
    <header class="container">
      <router-link :to="{ name: 'Home' }">
        <Logo></Logo>
      </router-link>
      <nav v-if="token">
        <router-link :to="{ name: 'Quotes' }" tag="button" class="button"
          >Quotes</router-link
        >
        <router-link :to="{ name: 'Pages' }" tag="button" class="button"
          >Pages</router-link
        >
        <router-link :to="{ name: 'Crawler' }" tag="button" class="button"
          >Crawler</router-link
        >
        <router-link :to="{ name: 'Search' }" tag="button" class="button"
          >Search</router-link
        >
        <button class="button is-info" @click="logout">Logout</button>
      </nav>
    </header>

    <main class="container">
      <!-- <transition name="slide"> -->
      <keep-alive>
        <router-view name="rv-main" />
      </keep-alive>
      <!-- </transition> -->
    </main>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Logo from '../../components/Logo';

export default {
  components: {
    Logo,
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  mounted() {
    this.handleResize();
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize);
  },
  updated() {
    this.handleResize();
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    handleResize() {
      const { innerWidth, innerHeight } = window;
      this.isMobile = innerWidth <= 768 ? true : false;
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'Login' });
    },
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
      token: (state) => state.token,
    }),
    pageName() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="scss" scoped>
// https://vuejs.org/v2/guide/transitions.html
.slide-leave {
  opacity: 0.5;
  transform: translateX(0vw);
}
.slide-leave-to {
  opacity: 0;
  transform: translateX(-30vw);
}
.slide-enter {
  opacity: 0;
  transform: translateX(30vw);
}
.slide-enter-active {
  transition: opacity 0.7s, transform 0.7s;
}
.slide-enter-to {
  opacity: 1;
  transform: translateX(0);
}
</style>
