import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store/index';
// layouts
import AppLayout from './views/layouts/AppLayout';
import NakedLayout from './views/layouts/NakedLayout';
// views
import Home from './views/Home.vue';
import Quotes from './views/Quotes.vue';
import Pages from './views/Pages.vue';
import Crawler from './views/Crawler.vue';
import Search from './views/Search.vue';
import Login from './views/Login.vue';
import NotFound from './views/NotFound.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    components: {
      'rv-master-view': AppLayout
    },
    children: [
      {
        name: 'Home',
        path: '/',
        components: {
          'rv-main': Home
        }
      }
    ]
  },

  {
    path: '/quotes',
    components: {
      'rv-master-view': AppLayout
    },
    children: [
      {
        name: 'Quotes',
        path: '/',
        components: {
          'rv-main': Quotes
        }
      }
    ]
  },

  {
    path: '/pages',
    components: {
      'rv-master-view': AppLayout
    },
    children: [
      {
        name: 'Pages',
        path: '/',
        components: {
          'rv-main': Pages
        }
      }
    ]
  },

  {
    path: '/crawler',
    components: {
      'rv-master-view': AppLayout
    },
    children: [
      {
        name: 'Crawler',
        path: '/',
        components: {
          'rv-main': Crawler
        }
      }
    ]
  },

  {
    path: '/search',
    components: {
      'rv-master-view': AppLayout
    },
    children: [
      {
        name: 'Search',
        path: '/',
        components: {
          'rv-main': Search
        }
      }
    ]
  },

  {
    name: '404',
    path: '/error/404',
    components: {
      'rv-master-view': NakedLayout
    },
    children: [
      {
        name: 'NotFound',
        path: '/',
        components: {
          'rv-main': NotFound
        }
      }
    ]
  },

  {
    name: 'Login',
    path: '/login',
    components: {
      'rv-master-view': AppLayout
    },
    children: [
      {
        name: 'Login',
        path: '/',
        components: {
          'rv-main': Login
        }
      }
    ]
  },

  {
    path: '*',
    redirect: { name: '404' }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // console.log({ to, from });
  // if (process.env.NODE_ENV === 'development') next();
  if (to.name === 'Login') next();
  //validate auth, if fails redirect
  store
    .dispatch('validateToken')
    .then(() => {
      // console.log('router valid token exists');
      next();
    })
    .catch(() => {
      // console.warn('router invalid token');
      store.dispatch('logout');
      next({ name: 'Login' });
    });
});

export default router;
