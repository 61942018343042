import Vue from 'vue';
import Buefy from 'buefy';
import App from './App.vue';
import router from './router';
import store from './store';
import './scss/buefyConfig.scss';
import './scss/style.scss';

Vue.use(Buefy, {
  defaultIconPack: 'mdi' //fad (duotone), fas (solid)
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  beforeCreate() {
    this.$store.dispatch('initApp');
  },
  render: h => h(App)
}).$mount('#app');
